import {Container, Row, Col } from "reactstrap";
import TableData from "./TableData";
import {withRouter} from "react-router-dom";

const Home = ({history}) => {

    return(
        <Container fluid={true}>
            <Row>
                <Col md={12}>
                    <h1 className={'text-center'}>Accurate Image</h1>
                </Col>
            </Row>
            <br/><br/>
            <TableData history={history}/>

        </Container>
    )
}

export default withRouter(Home);
