import {useContext} from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {GlobalContext} from "./provider/GlobalState";
import Home from "./app/home/Home";
import DetilBarang from "./app/detil/DetilBarang";

const App = () => {
      const {Loading} = useContext(GlobalContext);

      return (
          Loading ?
            <h1>Loading...</h1> :
              <Router>
                  <Switch>
                      <Route path="/" exact component={Home}/>
                      <Route path="/detil/:id" exact component={DetilBarang}/>
                  </Switch>
              </Router>
      );
}

export default App;
