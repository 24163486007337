import React, {useState, useEffect, createContext} from 'react';
import axios from 'axios';

export const GlobalContext = createContext();

export const GlobalProvider = ({children}) => {
    const BaseUrl = 'https://apistore.twincom.co.id/public';
    const NodeUrl = 'https://store.twincom.co.id';
    const [Loading, setLoading] = useState(true);
    const [NamaBarangDetil, setNamaBarangDetil] = useState('');
    const [Page, setPage] = useState(1);
    const [cari, setCari] = useState('')
    const [Kategori, setKategori] = useState([]);
    const [KategoriValue, setKategoriValue] = useState([]);

    const DapatkanToken = async () => {
        await axios({
            method: 'post',
            url: `${NodeUrl}/api/accurate/token`,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            console.log(res.data)
        }).catch(function (error) {
            console.log(error)
        });
        setLoading(false)

    }

    // const getDBSession = async (token) => {
    //     const FormData = queryString.stringify({
    //         token : token,
    //         id_db : ID_DB
    //     });
    //     await axios({
    //         method: 'post',
    //         url: `${NodeUrl}/api/accurate`,
    //         data: FormData,
    //         headers: {
    //             'Accept': 'application/json',
    //             "Content-Type": "application/x-www-form-urlencoded"
    //         }
    //     }).then(res => {
    //         setDBSession(res.data.d.session)
    //     }).catch(function (error) {
    //         console.log(error)
    //     });
    //     setLoading(false);
    // }

    useEffect(() => {
        DapatkanToken();
    },[]);

    const State = {Loading, NodeUrl, BaseUrl, NamaBarangDetil, setNamaBarangDetil, Page, setPage, cari, setCari, Kategori, setKategori, KategoriValue, setKategoriValue}

    return (
        <GlobalContext.Provider value={State}>
            {children}
        </GlobalContext.Provider>
    )
}
