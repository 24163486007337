import {useState, useContext, useEffect, useCallback} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams, Link } from "react-router-dom";
import {GlobalContext} from "../../provider/GlobalState";
import {Col, Container, Row} from "reactstrap";
import {useDropzone} from 'react-dropzone'
import Thumb from "./Thumb";
import axios from "axios";

const DetilBarang = () => {
    let { id } = useParams();
    const {BaseUrl, NamaBarangDetil, NodeUrl} = useContext(GlobalContext);
    const [Loading, setLoading] = useState(true);
    const [DataGambar, setDataGambar] = useState([]);
    const [Form, SetForm] = useState([])
    const [Gambar, setGambar] = useState([]);
    const [Spesifikasi, setSpesifikasi] = useState([]);
    const [SpekWajib, setSpekWajib] = useState({
        barang_id : id, berat : '', minimum_pembelian : '', dapat_dijual : ''
    });
    const [LoadingSpekWajib, setLoadingSpekWajib] = useState(false);
    const [FormTambahSpek, setFormTambahSpek] = useState([{
        barang_id : id, urutan : '', nama_spesifikasi : '', isi_spesifikasi : '',
    }]);
    const [FormEditSpek, setFormEditSpek] = useState([{
        id : '', barang_id : '', urutan : '', nama_spesifikasi : '', isi_spesifikasi : '',
    }]);

    const [modalTambah, setModalTambah] = useState(false);
    const toggleModalTambah = () => setModalTambah(!modalTambah);

    const [modalEdit, setModalEdit] = useState(false);
    const toggleModalEdit = () => setModalEdit(!modalEdit);

    const [LoadingTombol, setLoadingTombol] = useState(false);


    const HandleCeklist = (index, idGambar) => {

        let newArr = [...DataGambar];
        if(newArr[index].ceklist === 1){
            newArr[index]['ceklist'] = 0;
        }else{
            newArr[index]['ceklist'] = 1;
        }
        setDataGambar(newArr);
    }

    const onDrop = useCallback(async acceptedFiles => {
        SetForm([...Form, acceptedFiles])

        for (let i = 0; i < acceptedFiles.length; i++) {
            setGambar(Gambar => [...Gambar, acceptedFiles[i]])
        }
    }, [Form])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const getData = async () => {
        await axios.get(`${BaseUrl}/api/gambar?barang_id=${id}`,{
            headers: {
                Accept: 'application/json',
            }
        }).then(res => {
            setDataGambar(res.data)
        }).catch(err => {
            console.log(err.response);
        });
        setLoading(false);
    }

    const getSpekWajib = async () => {
        await axios.get(`${BaseUrl}/api/spek_wajib?barang_id=${id}`,{
            headers: {
                Accept: 'application/json',
            }
        }).then(res => {
            setSpekWajib({
                ...SpekWajib, berat : res.data.berat, minimum_pembelian : res.data.minimum_pembelian, dapat_dijual : res.data.dapat_dijual
            })
        }).catch(err => {
            console.log(err.response);
        });
    }

    const getSpesifikasi = async () => {
        await axios.get(`${BaseUrl}/api/get_spesifikasi?barang_id=${id}`,{
            headers: {
                Accept: 'application/json',
            }
        }).then(res => {
            setSpesifikasi(res.data)
        }).catch(err => {
            console.log(err.response);
        });
    }


    const TambahFile = async () => {
        setLoading(true);
        let bodyFormData = new FormData();
        bodyFormData.append('barang_id', id);
        let JumlahData = 0;
        let nomor = 0;
        for (let i = 0; i < Form.length; i++) {
            JumlahData += parseInt(Form[i].length);
            for(let g = 0; g < Form[i].length; g++){
                nomor++;
                bodyFormData.append(`file_${nomor - 1}`, Form[i][g])
            }
        }
        bodyFormData.append('jumlah', JumlahData);
        await axios({
            method : 'post',
            url : `${BaseUrl}/api/gambar`,
            data: bodyFormData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            getData();
            setGambar([]);
            SetForm([]);
        }).catch(function (error) {
            console.log(error);
        });
        setLoading(false);
    };

    const Hapus = async () => {
        setLoading(true);
        await axios({
            method : 'delete',
            url : `${BaseUrl}/api/gambar`,
            data: {
                data : DataGambar
            },
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            getData();
        }).catch(function (error) {
            console.log(error);
        });
        setLoading(false);
    }

    const UpdateSpekWajib = async () => {
        setLoadingSpekWajib(true);
        await axios({
            method : 'post',
            url : `${BaseUrl}/api/spek_wajib`,
            data : SpekWajib,
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            alert('berhasil')
        }).catch(e => {
            console.log(e)
        })
        setLoadingSpekWajib(false);
    }


    const CobaTambahSpek = async () => {
        setLoadingTombol(true)
        await axios({
            method : 'post',
            url : `${BaseUrl}/api/spesifikasi`,
            data : {
                barang_id : id,
                data : FormTambahSpek
            },
            headers: {
                'Accept': 'application/json',
            }
        }).then(async res => {
            await getSpesifikasi();
            setFormTambahSpek([{barang_id : id, urutan : '', nama_spesifikasi : '', isi_spesifikasi : ''}])
            toggleModalTambah();
        }).catch(e => {
            console.log(e)
        })
        setLoadingTombol(false)
    }

    const CobaUbahSpek = async () => {
        setLoadingTombol(true)
        await axios({
            method : 'put',
            url : `${BaseUrl}/api/spesifikasi`,
            data : FormEditSpek,
            headers: {
                'Accept': 'application/json',
            }
        }).then(async res => {
            await getSpesifikasi();
            setFormEditSpek([{id : '', barang_id : '', urutan : '', nama_spesifikasi : '', isi_spesifikasi : ''}])
            toggleModalEdit();
        }).catch(e => {
            console.log(e)
        })
        setLoadingTombol(false)
    }

    const CobaHapusSpek = async (IdSpek) => {
        await axios({
            method : 'delete',
            url : `${BaseUrl}/api/spesifikasi`,
            data : {
                id : IdSpek,
            },
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            getSpesifikasi()
        }).catch(e => {
            console.log(e)
        })
    }

    const UbahUrutanSpek = (IdSpek, Action) => {
        axios({
            method : 'put',
            url : `${NodeUrl}/api/spek`,
            data : {
                id : IdSpek,
                action : Action
            },
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            getSpesifikasi()
        }).catch(e => {
            console.log(e)
        })
    }


    useEffect(()=> {
        getData();
        getSpekWajib();
        getSpesifikasi();
        return(() => {
            setDataGambar([]);
        })
    }, []);
    return(
        <Container fluid={true}>
            <br/>
            <Row>
                <Col md={1}>
                    <Link to={'/'}>
                        <button className='btn btn-warning btn-sm'>Kembali</button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h3 className={'text-center'} style={{marginLeft : '-50px'}}>{NamaBarangDetil}</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div {...getRootProps()} className='border_dropzone'>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <h1 className={'text-center'}>..Lepaskan Sekarang!!</h1> :
                                Gambar.length === 0 ? <h1 className='text-center'>Klik atau taruh gambar disini!!</h1>
                                    :
                                    Gambar.map((file, index) => <Thumb key={index} file={file}/>)
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <button className={'btn btn-info btn-block'} onClick={TambahFile}>Simpan</button>
                </Col>
            </Row>
            <br/><br/>
            <Row>
                <Col md={12}>
                   <Row>
                       <Container>
                           <Row>
                               {
                                   Loading ? <h3>Loading...</h3> :
                                   DataGambar.map((list, index) =>
                                       <Col md={3} key={index} onClick={() => HandleCeklist(index, list.id)}>
                                           <img src={`${BaseUrl}/barang/${list.path}`} className={`img img-thumbnail ${list.ceklist === 1 ? 'habang' : null}`} style={{width : '200px', height : '200px'}}/>
                                       </Col>
                                   )
                               }
                           </Row>
                       </Container>
                   </Row>
                </Col>
            </Row>

            <br/><br/>
            <Row>
                <Col md={12}>
                    <button className={'btn btn-danger btn-block'} onClick={Hapus}>Hapus</button>
                </Col>
            </Row>
            <br/><br/>

            <Row>
                <Col md={12}>
                    <h1>Spesifikasi Wajib Barang</h1>
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">* Berat Barang</label>
                        <input type="number" value={SpekWajib.berat} onChange={(e) => setSpekWajib({...SpekWajib, berat : e.target.value})} className="form-control" placeholder="Berat Barang Wajib di isi"/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">* Minimum Pembelian</label>
                        <input type="number" value={SpekWajib.minimum_pembelian} onChange={(e) => setSpekWajib({...SpekWajib, minimum_pembelian : e.target.value})} className="form-control" placeholder="Minimum Pembelian Wajib di isi"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">* Apakah ini barang sub-paket yang tidak boleh dijual</label>
                        <select className="form-control" value={SpekWajib.dapat_dijual} onChange={(e) => setSpekWajib({...SpekWajib, dapat_dijual : e.target.value})} >
                            <option value={''}>Pilih</option>
                            <option value={1}>Tidak</option>
                            <option value={2}>Iya</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={12}>
                    <button disabled={LoadingSpekWajib} className={'btn btn-success btn-block'} onClick={UpdateSpekWajib}>{LoadingSpekWajib ? 'Loading...' : 'Simpan Perubahan'}</button>
                </Col>
            </Row>
                <br/><br/>
            <Row>
                <Col md={10}>
                    <h1>Spesifikasi Tambahan Barang</h1>
                </Col>
                <Col md={2}>
                    <button className='btn btn-sm btn-primary float-right' onClick={toggleModalTambah}>Tambah</button>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={12}>
                    <table className={'table'}>
                        <tbody>
                            <tr>
                               <td>Nomor</td>
                               <td>Nama Spesifikasi</td>
                               <td>Isi Spesifikasi</td>
                               <td>Aksi</td>
                            </tr>
                            {
                                Spesifikasi.map((list, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{list.nama_spesifikasi}</td>
                                        <td>{list.isi_spesifikasi}</td>
                                        <td>
                                            <button className='btn btn-success btn-sm mr-2' onClick={async () => {
                                                await setFormEditSpek({id : list.id, barang_id : list.barang_id, urutan : list.urutan, nama_spesifikasi : list.nama_spesifikasi, isi_spesifikasi : list.isi_spesifikasi})
                                                toggleModalEdit();
                                            }}>Ubah</button>
                                            <button className='btn btn-danger btn-sm mr-2' onClick={() => CobaHapusSpek(list.id)}>Hapus</button>
                                            
                                            {
                                                Number(list.urutan) !== 1 ?
                                                <button className='btn btn-info btn-sm mr-2' onClick={() => UbahUrutanSpek(list.id,'naik')}>Naikan</button> : null
                                            }

                                            {
                                                Number(list.urutan) !== Spesifikasi.length ?
                                                <button className='btn btn-warning btn-sm' onClick={() => UbahUrutanSpek(list.id, 'turun')}>Turunkan</button> : null
                                            }
                                            
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
            <br/><br/>
            <Modal size={'lg'} isOpen={modalTambah} toggle={toggleModalTambah}>
                <ModalHeader toggle={toggleModalTambah}>Tambah Spesifikasi</ModalHeader>
                <ModalBody>
                    {
                        FormTambahSpek.map((list, index) =>
                            <Row>
                                <Col md={5}>
                                    <div className="form-group">
                                        <input type="text" value={list.nama_spesifikasi} onChange={(e) => {
                                            let OldData = [...FormTambahSpek];
                                            OldData[index]['nama_spesifikasi'] = e.target.value;
                                            setFormTambahSpek(OldData)
                                        }} className="form-control" placeholder="Nama Spesifikasi"/>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="form-group">
                                        <input type="text" value={list.isi_spesifikasi}
                                               onChange={(e) => {
                                                   let OldData = [...FormTambahSpek];
                                                   OldData[index]['isi_spesifikasi'] = e.target.value;
                                                   setFormTambahSpek(OldData)
                                               }}
                                               className="form-control" placeholder="Isi Spesifikasi"/>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <button className='btn btn-danger btn-sm mt-1' onClick={() => {
                                        let OldData = [...FormTambahSpek]
                                        const NewData = OldData.filter((item, indexarr) => indexarr !== index)
                                        setFormTambahSpek(NewData)
                                    }}>X</button>
                                    {
                                        FormTambahSpek.length === (index + 1) ?
                                            <button className='btn btn-success btn-sm mt-1 ml-2' onClick={() => {
                                                setFormTambahSpek([...FormTambahSpek, {
                                                    barang_id : id, urutan : '', nama_spesifikasi : '', isi_spesifikasi : '',
                                                }])
                                            }}>+</button> : null
                                    }
                                </Col>
                            </Row>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button disabled={LoadingTombol} color="primary" onClick={CobaTambahSpek}>{!LoadingTombol ? 'Tambah Spesifikasi' : 'Loading'}</Button>{' '}
                    <Button color="secondary" onClick={toggleModalTambah}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal size={'lg'} isOpen={modalEdit} toggle={toggleModalEdit}>
                <ModalHeader toggle={toggleModalEdit}>Ubah Spesifikasi</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6}>
                            <div className="form-group">
                                <input type="text" value={FormEditSpek.nama_spesifikasi} onChange={(e) => setFormEditSpek({...FormEditSpek, nama_spesifikasi : e.target.value})} className="form-control" placeholder="Nama Spesifikasi"/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <input type="text" value={FormEditSpek.isi_spesifikasi} onChange={(e) => setFormEditSpek({...FormEditSpek, isi_spesifikasi : e.target.value})} className="form-control" placeholder="Isi Spesifikasi"/>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={LoadingTombol} color="primary" onClick={CobaUbahSpek}>{!LoadingTombol ? 'Ubah Spesifikasi' : 'Loading'}</Button>{' '}
                    <Button color="secondary" onClick={toggleModalEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default DetilBarang;
