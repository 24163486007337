import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Button, Col, Input, Row, Table} from 'reactstrap';
import {GlobalContext} from "../../provider/GlobalState";
import axios from "axios";
import queryString from "query-string";
import Icon from "awesome-react-icons";
import Pagination from "react-js-pagination";
import Select from 'react-select'



const TableDataKomponent = ({history}) => {
    const {NodeUrl, setNamaBarangDetil, Page, setPage, cari, setCari, BaseUrl, Kategori, setKategori, KategoriValue, setKategoriValue} = useContext(GlobalContext);
    const [Loading, setLoading] = useState(true);
    const [Data, setData] = useState([]);
    const [ListKategori, setListKategori] = useState([])

    const [SP, setSP] = useState({
        page: 1,
        pageCount: 0,
        rowCount: 0
    })

    const GetData = useCallback(async () => {
        setLoading(true)
        const FormData = queryString.stringify({
            cari : cari.replaceAll(" ", "%25"),
            page : Page,
            kategori : JSON.stringify(Kategori)
        });
        await axios({
            method: 'post',
            url: `${NodeUrl}/api/item`,
            data: FormData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/x-www-form-urlencoded"
            }
        }).then(res => {
            setData(res.data.d)
            setSP(res.data.sp);
        }).catch(function (error) {
           alert(error)
        });
        setLoading(false);
    }, [Page, cari, Kategori])

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    const GetKategori = async () => {
        await axios({
            method: 'post',
            url: `${NodeUrl}/api/kategori/list`,
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            const NewValue = [];
            res.data.map(item => {
                NewValue.push({
                    value : item.kategori_id,
                    label : item.nama_sub_kategori
                })
            })
            setListKategori(NewValue)
        }).catch(function (error) {
            alert(error)
        });
    }

    const HandleChangeKategori = (selectedOption) => {
        setKategoriValue(selectedOption)
        let NewData = [];
        selectedOption.map(item => {
            NewData.push({
                id : item.value
            })
        })

        setKategori(NewData);
    }



    useEffect(() => {
        GetData();
        GetKategori();
    }, [GetData])

    return(
            <React.Fragment>
                <Row>
                    <Col md={4}>
                        <Input type="text" name="cari" value={cari} onChange={(e) => setCari(e.target.value)} placeholder="Cari Barang disini" />
                    </Col>
                    <Col md={2}>
                        <Button onClick={GetData}><Icon name="search" /></Button>
                    </Col>
                    <Col md={1}>

                    </Col>
                    <Col md={5}>
                       <div className={'float-right'}>
                           <Pagination
                               activePage={Page}
                               itemsCountPerPage={SP.pageSize}
                               totalItemsCount={SP.rowCount}
                               pageRangeDisplayed={5}
                               itemClass="page-item"
                               linkClass="page-link"
                               onChange={handlePageChange.bind(this)}
                           />
                       </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Select
                            options={ListKategori}
                            value={KategoriValue}
                            isMulti
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={HandleChangeKategori}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={12}>
                        <Table>
                            <thead>
                            <tr>
                                <th>Nomor Barang</th>
                                <th>Nama Barang</th>
                                <th>Gambar</th>
                                <th>Detil</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Loading ?
                                    <tr>
                                        <td colSpan={3}>Loading Data</td>
                                    </tr> :
                                    Data.map((list, index) =>
                                        <tr key={index}>
                                            <td>{list.no}</td>
                                            <td>{list.name}</td>
                                            <td width='20%'>{
                                                list.gambar.map((list, index) =>
                                                    <img key={index} src={`${BaseUrl}/barang/${list.path}`} className='img-thumbnail' style={{width : '50px', height : '50px'}}/>
                                                )
                                            }</td>
                                            <td><button onClick={() => {
                                                setNamaBarangDetil(list.name);
                                                history.push(`/detil/${list.id}`);
                                            }} className='btn btn-info btn-sm'>Detil</button></td>

                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
    )
}

const TableData = React.memo(TableDataKomponent);
export default TableData;
