import {useEffect, useState} from 'react';

const Thumb = ({file}) => {
    const [Gambarnya, setGambarnya] = useState('');

    useEffect(() => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setGambarnya(...Gambarnya, reader.result)
        };
    }, [])


    return(
        <img src={Gambarnya} className='img img-thumbnail' style={{width : '100px', height : '90px', marginRight : '20px'}}/>
    )
}

export default Thumb;
